body {
  font-family: 'Inter', sans-serif;
  background-color: #00093E;
}

.aside.aside-dark {
  background-color: #00093E;
}

.aside.aside-dark .aside-logo {
  background-color: #00062D;
}

.aside-dark .menu .menu-item.show>.menu-link {
  transition: color .2s ease, background-color .2s ease;
  background-color: #091155;
  color: #fff;
}

.aside-dark .menu .menu-item .menu-link.active {
  transition: color .2s ease, background-color .2s ease;
  background-color: #091155;
  color: #fff;
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
  color: #71E7EF;
}

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here),
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
  transition: color .2s ease, background-color .2s ease;
  background-color: #091155;
  color: #fff;
}

.bg-dark {
  background-color: #00093E !important;
}

.header-fixed .header {
  background-color: #03093e;
}

.btn.btn-light-dark {
  color: #fff;
  border-color: #3D4762;
  background-color: #3D4762;
}

.btn-check:active+.btn.btn-light-dark,
.btn-check:checked+.btn.btn-light-dark,
.btn.btn-light-dark.active,
.btn.btn-light-dark.show,
.btn.btn-light-dark:active:not(.btn-active),
.btn.btn-light-dark:focus:not(.btn-active),
.btn.btn-light-dark:hover:not(.btn-active),
.show>.btn.btn-light-dark {
  color: #fff;
  border-color: #3D4762;
  background-color: #49536c !important;
}

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected {
  background-color: #2a334f;
}

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background-color: rgba(113, 231, 239, 0.19);
  color: #29334f;
  transition: color .2s ease, background-color .2s ease;
}

.text-dark {
  color: #3D4762 !important;
}

.text-primary {
  color: #71E7EF !important;
}

.form-control[readonly] {
  color: #fff;
  background-color: #29334E;
  border: #29334E;
}

.btn-outline-secondary-zii {
  color: #6A7592;
  background: #6A7592;
  border-color: #6A7592;
}

.btn-outline-secondary-zii:hover {
  color: #29334E;
  background-color: #545e78;
  border-color: #757e96;
}

.btn.btn-primary {
  color: #29334E;
  border-color: #71E7EF;
  background-color: #71E7EF;
}

.btn-check:active+.btn.btn-primary,
.btn-check:checked+.btn.btn-primary,
.btn.btn-primary.active,
.btn.btn-primary.show,
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.show>.btn.btn-primary {
  color: #29334E;
  border-color: #4dcbd4;
  background-color: #4dcbd4 !important;
}

.btn.btn-light-primary {
  color: #29334E;
  border-color: #edeff6;
  background-color: #edeff6;
}

.btn-check:active+.btn.btn-light-primary,
.btn-check:checked+.btn.btn-light-primary,
.btn.btn-light-primary.active,
.btn.btn-light-primary.show,
.btn.btn-light-primary:active:not(.btn-active),
.btn.btn-light-primary:focus:not(.btn-active),
.btn.btn-light-primary:hover:not(.btn-active),
.show>.btn.btn-light-primary {
  color: #29334E;
  border-color: #71E7EF;
  background-color: #71E7EF !important;
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled),
.nav-line-tabs .nav-item.show .nav-link {
  color: #3D4762;
  border-bottom: 1px solid #71E7EF;
}

.btn.btn-light-dark .svg-icon,
.btn.btn-light-dark i {
  color: #fff;
}

.card.zii {
  position: relative;
  overflow-y: hidden;
  background-color: #3D4762 !important;
}

.card.zii-history {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  z-index: 2;
  opacity: 0;
  background-color: rgba(0, 9, 62, 0.85) !important;

  transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;

}

.card.zii-history.m-shown {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 2;
  opacity: 1;
  background-color: rgba(0, 9, 62, 0.85) !important;

  transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

.card.zii-history.lists {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  z-index: 2;
  opacity: 0;
  background-color: rgba(0, 9, 62, 0.85) !important;

  transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

.card.zii-history.lists.m-shown {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 2;
  opacity: 1;
  background-color: rgba(0, 9, 62, 0.85) !important;

  transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

.card.zii-history .card-header {
  border-bottom: 1px solid #222c62 !important;
}

.btn-check:active+.btn.btn-active-color-primary,
.btn-check:checked+.btn.btn-active-color-primary,
.btn.btn-active-color-primary.active,
.btn.btn-active-color-primary.show,
.btn.btn-active-color-primary:active:not(.btn-active),
.btn.btn-active-color-primary:focus:not(.btn-active),
.btn.btn-active-color-primary:hover:not(.btn-active),
.show>.btn.btn-active-color-primary {
  color: #71E7EF;
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: #71e7ef;
}

.scrolltop {
  background-color: #3D4762;
}

.form-control.form-control-solid,
.form-select.form-select-solid {
  background-color: #29334E;
  border-color: #29334E;
  color: #fff;
}

.dropdown.show>.form-select.form-select-solid,
.form-select.form-select-solid.active,
.form-select.form-select-solid.focus,
.form-select.form-select-solid:active,
.form-select.form-select-solid:focus {
  background-color: #29334E;
  border-color: #29334E !important;
  color: #fff;
  transition: color .2s ease, background-color .2s ease;
}

.select2-container--bootstrap5.select2-container--focus .form-select-solid,
.select2-container--bootstrap5.select2-container--open .form-select-solid {
  background-color: #29334E;
}

.select2-container--bootstrap5 .select2-selection--single.form-select-solid .select2-selection__rendered {
  color: #fff;
}

.dropdown.show>.form-control.form-control-solid,
.form-control.form-control-solid.active,
.form-control.form-control-solid.focus,
.form-control.form-control-solid:active,
.form-control.form-control-solid:focus {
  background-color: #222b43;
  border-color: #29334E;
  color: #fff;
}

.page-item.active .page-link {
  color: #29334E;
}

.page-item.active .page-link {
  z-index: 3;
  color: #29334E;
  background-color: #71e7ef;
  border-color: transparent;
}

.nav-line-tabs .nav-item .nav-link {
  margin: 0.25rem 0 0;
}

.pms-enable {
  position: relative;
}

.pms-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 50;
  background-color: rgba(0, 9, 62, 0.85) !important;
}

.pms-body {
  height: 100%;
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-word-wrap {
  word-wrap: break-word;
}

@media (max-width: 575.98px) {
  .text-word-wrap {
    width: 188px;
    word-wrap: break-word;
  }
}

@media (min-width: 1200px) {
  .card.zii-history.lists .card-header {
    min-height: 40px;
  }

  .card.zii-history.lists .card-body .table>:not(caption)>*>* {
    padding: 0.18rem 0.75rem;
    margin-bottom: 0.25rem !important;
  }
}